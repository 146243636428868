import {
    faBed,
    faFlag,
    faGolfBallTee,
    faMap,
    faPlane,
    faTaxi,
    faTrophy,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icons from 'src/assets/icon';
import { formatCurrency } from 'src/const/contain';
import { roleSelector } from 'src/redux/selectors/checkRoleSelecter';
import GroupSummaryStyle from './style';
import { Input, Popover } from 'antd';
import { updateEditPriceSuccess } from 'src/redux/actions/updatePriceAction';
import { Accommodation, Item } from 'src/types';

interface GroupPriceProps {
    item?: any;
    tourName?: any;
    priceChange?: any;
}

const GroupPrice: React.FC<GroupPriceProps> = ({
    item,
    tourName,
    priceChange,
}) => {
    const [show, setShow] = useState<boolean>(false);
    const isRole = useSelector(roleSelector);
    const [viewToInput, setChangeViewToInput] = useState<boolean>(false);
    const [inputvalue, setInputvalue] = useState<any>('');
    const inputPriceRef = useRef<HTMLDivElement | null>(null);
    const isOutsideRef = useRef(false);
    const dispatch = useDispatch();
    const [priceChangeState, setPriceChangeState] = useState<any>('');

    const changerViewToInput = () => {
        setChangeViewToInput(!viewToInput);
    };

    const showDetailPrice = () => {
        setShow(!show);
    };
    const handChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setInputvalue(value);
        }
        if (value === '0') {
            setInputvalue('0');
        }
    };

    useEffect(() => {
        const valuePrice = {
            nameGroup: item?.group,
            price: inputvalue,
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (
                inputPriceRef.current &&
                !inputPriceRef.current.contains(event.target as Node)
            ) {
                if (!isOutsideRef.current) {
                    dispatch(updateEditPriceSuccess(valuePrice));
                    isOutsideRef.current = true;
                }
            } else {
                isOutsideRef.current = false;
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dispatch, inputvalue, item?.group]);

    const dataPrice =
        (item.items as {
            _price: number;
            _startDate: any;
        }[]) || [];
    const price = dataPrice?.length > 0 ? dataPrice[0]?._price : null;
    const roundedPrice = Math.round(Number(price || 0));
    const pricePerPerson = roundedPrice;
    const newPriceChange = formatCurrency(priceChange);
    const carHire = item?.items[0]?._carHire || [];
    const flight = item?.items[0]?._flights || [];

    useEffect(() => {
        setPriceChangeState(newPriceChange);
        setInputvalue(
            newPriceChange
                ? newPriceChange
                : newPriceChange === '0'
                ? '0'
                : roundedPrice,
        );
    }, [roundedPrice, priceChange]);

    // @ts-ignore
    const accommodationItems = item.items
        .map((item: Item) => item._accommodation)
        .flat();

    const roomTypeS = accommodationItems[0]?.roomType;

    const startDates = accommodationItems.map(
        (accommodation: Accommodation) => accommodation.startDate,
    );

    const nameHotel = accommodationItems.map(
        (accommodation: Accommodation) => accommodation.name,
    );
    const descriptionOfAccommoda = accommodationItems.map(
        (accommodation: Accommodation) => accommodation.boardBasis,
    );
    // @ts-ignore
    const golfTotal = item.items?.map((item: any) => {
        return item;
    });
    const golfRound = golfTotal?.map((tem: any) => {
        return tem?._golf;
    });

    const nightStay = accommodationItems.map(
        (accommodation: Accommodation) => accommodation.duration,
    );
    return (
        <GroupSummaryStyle>
            <div className="viewinforPayment">
                <div className="groupComponent">
                    <div className="nameGroups">{item?.group}</div>
                    <div className="title">(price per person)</div>
                </div>
                {isRole === 'Edit mode' ? (
                    <div>
                        {!viewToInput ? (
                            <Popover
                                placement="rightTop"
                                content={'Click to edit'}
                            >
                                <div
                                    className="numGroupsEdit"
                                    onClick={changerViewToInput}
                                >
                                    £{' '}
                                    {priceChangeState
                                        ? formatCurrency(
                                              Number(priceChangeState),
                                          )
                                        : formatCurrency(
                                              Number(pricePerPerson),
                                          )}
                                </div>
                            </Popover>
                        ) : (
                            <div className="numGroupsEdit" ref={inputPriceRef}>
                                <Input
                                    className="inputPriceNum"
                                    variant="borderless"
                                    onChange={handChangePrice}
                                    value={inputvalue}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="numGroups">
                        £{' '}
                        {priceChangeState
                            ? formatCurrency(Number(priceChangeState))
                            : formatCurrency(Number(pricePerPerson))}
                    </div>
                )}
                <img
                    alt=""
                    className="iconPlus"
                    src={show ? icons.minusIcon : icons.plusIcon}
                    onClick={showDetailPrice}
                />
            </div>
            {show && (
                <div className="inforGroup">
                    {tourName && (
                        <div className="detailInfor">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faTrophy}
                            />
                            <div className="titleDetailInfor">{tourName}</div>
                        </div>
                    )}
                    <div className="detailInfor">
                        {tourName ? (
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faTrophy}
                            />
                        ) : (
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faUser}
                            />
                        )}
                        <div className="titleDetailInfor">
                            {dataPrice.length} people arriving on{' '}
                            {moment(startDates[0]).format('MM/DD/YYYY')}
                        </div>
                    </div>
                    {nameHotel[0] && (
                        <div className="detailInfor">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faMap}
                            />
                            <div className="titleDetailInfor">
                                Staying at {nameHotel[0]}, {roomTypeS}
                            </div>
                        </div>
                    )}
                    {descriptionOfAccommoda[0] && (
                        <div className="detailInfor">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faBed}
                            />
                            <div className="titleDetailInfor">
                                {nightStay[0]} Night(s),{' '}
                                {descriptionOfAccommoda[0]}
                            </div>
                        </div>
                    )}
                    {golfRound[0]?.length > 0 && (
                        <div className="detailInfor">
                            {tourName ? (
                                <FontAwesomeIcon
                                    className="iconDetail"
                                    icon={faGolfBallTee}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    className="iconDetail"
                                    icon={faFlag}
                                />
                            )}
                            <div className="titleDetailInfor">
                                {' '}
                                {golfRound[0]?.length} Round(s) of Golf
                            </div>
                        </div>
                    )}
                    {flight?.length > 0 && (
                        <div className="detailInfor">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faPlane}
                            />
                            <div className="titleDetailInfor">
                                Flights included
                            </div>
                        </div>
                    )}
                    {carHire?.length > 0 && (
                        <div className="detailInfor">
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faTaxi}
                            />
                            <div className="titleDetailInfor">
                                Car hire included
                            </div>
                        </div>
                    )}
                </div>
            )}
        </GroupSummaryStyle>
    );
};

export default GroupPrice;
