
import styled from '@emotion/styled';

const TransferCardStyle = styled('div')`
    margin-bottom: 16px;
    .titleFightPlan {
        color: #000;
        font-size: 20px;
        font-weight: 700;
    }
    .traditionView {
        border-radius: 4px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        display: flex;
        justify-content: space-between;
    }
    .viewDetailPlanFlight {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .headerCard {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .viewFlightinformation {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        justify-content: end;
        margin-top: 16px;
        align-items: end;
    }
    .iconTransportation {
        color: #12b897;
        margin-right: 12px;
    }
    .iconPassenger{
        color: #12b897;
        margin-left: 12px;
    }
    .detailPlanflight {
        display: flex;
        align-items: flex-start;
        margin-top: 16px;
    }
    .textFont {
        color: #000;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
    }
    .textTimeFont {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
    }
    .locationName {
        width: 80%;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.2px;
    }
`;

export default TransferCardStyle;
