export const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = phoneNumber?.replace(/\D/g, '');
    if (cleaned?.length !== 11) {
        return 'Invalid phone number';
    }
    const formattedNumber = cleaned?.replace(
        /(\d{4})(\d{3})(\d{4})/,
        '$1 $2 $3',
    );
    return formattedNumber;
};

export const processNumber = (num?: number): number => {
    if (num === undefined || num === null) {
        return 0;
    }

    if (num % 2 === 0) {
        return num;
    } else {
        return num - 1;
    }
};

export const formatCurrency = (amount: number): string => {
    return amount?.toLocaleString('en-US', {
        minimumFractionDigits: 0, 
        maximumFractionDigits: 0,
    });
};

interface GroupItem {
    group: string;
    items: any[];
}

export const sortGroupsByABC = (groups: GroupItem[]): GroupItem[] => {
    return groups.sort((a, b) => a.group.localeCompare(b.group));
};

export const getFirstLetter = (str: string) => {
    return str.charAt(0);
};
