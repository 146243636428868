import styled from '@emotion/styled';

const ViewYourMasterExperientComponentStyle = styled('div')`
    width: 100%;
    .Header {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.4px;
    }
    .Body {
        padding: 15px;
    }
    .detailInfor{
        margin-bottom: 6px;
    }
    .titleDetailInfor {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
`;

export default ViewYourMasterExperientComponentStyle;
