import { combineReducers } from '@reduxjs/toolkit';
import { authenReducer } from './authenReducer';
import { roleCheckReducer } from './checkRoleReducer';
import { getListQouteReducer } from './getListQuoteReducer';
import { getQouteDetailReducer } from './quoteDetailReducer';
import { editQuoteTitleReducer } from './editQuoteTitleReducer';
import { editQuoteIntroReducer } from './editQuoteIntroReducer';
import { editPricePersonReducer } from './editPriceReducer';


const rootReducer = combineReducers({
    authen: authenReducer,
    roleCheck: roleCheckReducer,
    listQuote: getListQouteReducer,
    quoteDetail: getQouteDetailReducer,
    editQuoteTitle: editQuoteTitleReducer,
    editQuoteIntro: editQuoteIntroReducer,
    editPricePerson: editPricePersonReducer,
});

export { rootReducer };
