import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import QuoteDetailPageStyle from './style';
import MobileQouteDetailPage from './mobile';
import DeskTopQuoteDetailPage from './desktop';
import { getDetailQuote } from 'src/api/apiGetDetailQuote';
import { getQuoteDetailJson } from 'src/api/apiGetQuoteDetailJson';
import { getImageApi } from 'src/api/apiGetImage';

const QuoteDetailPage = () => {
    const [data, setData] = useState<any>();
    const [mainImage, setMainImage] = useState<any>();
    const [dataJson, setDataJson] = useState<any>();
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const fetchData = async () => {
        try {
            const [response, responseDataJson] = await Promise.all([
                getDetailQuote(),
                id ? getQuoteDetailJson(id) : Promise.resolve(null),
            ]);

            if (responseDataJson) {
                setDataJson(responseDataJson);
            } else {
                setDataJson(null);
            }

            setData(response?.fields);

            const defaultImageId = response?.fields?.defaultImage?.sys?.id;
            if (defaultImageId) {
                try {
                    const imageResponse = await getImageApi(defaultImageId);
                    setMainImage(imageResponse);
                } catch (err) {
                    setMainImage(null);
                }
            }
        } catch (err) {
            setData(null);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    const imageIntroDuction = `http:${mainImage?.fields?.file?.url}`;
    return (
        <Router>
            <QuoteDetailPageStyle>
                <div className="deskTopView">
                    <DeskTopQuoteDetailPage Imange={imageIntroDuction} dataContenFull={data} dataJsonChild={dataJson} onUpdate={fetchData}/>
                </div>
                <div className="mobileView">
                    <MobileQouteDetailPage Imange={imageIntroDuction} dataContenFull={data} dataJsonChild={dataJson}/>
                </div>
            </QuoteDetailPageStyle>
        </Router>
    );
};

export default React.memo(QuoteDetailPage);
