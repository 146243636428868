import React from 'react';
import ItineraryInDetailStyle from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import IntineraryStandardComponent from './ItineraryInDetailStandard';
import { Skeleton } from 'antd';
import ItineraryInDetailEventComponent from './ItineraryInDeatilEvent';

interface ItineraryInDetailProps {
    customProp?: any;
    dataJsonProp?: any;
    image?: string;
    onUpdateValueInput?: (value: any) => void;
}

const ItineraryInDetailComponent: React.FC<ItineraryInDetailProps> = (
    props,
) => {
    const { customProp, dataJsonProp, image, onUpdateValueInput } = props;
    const queryParams = new URLSearchParams(window.location.search);
    const type = queryParams.get('type');
    const tourName = dataJsonProp?.tourName || '';
    const words = tourName?.split(' ');
    const firstTwoWords = words[0] === "Masters" ? "Masters" : `${words[0]} ${words[1]}`;
    const quoteWhyBookWithYgtTheOpen =
        customProp?.quoteWhyBookWithYgtTheOpen?.content[0]?.content || [];
    const quoteWhyBookWithYgtTheRyderCup =
        customProp?.quoteWhyBookWithYgtTheRyderCup?.content[0]?.content || [];
    const quoteWhyBookWithYgtTheMasters =
        customProp?.quoteWhyBookWithYgtTheMasters?.content[0]?.content || [];
    const quoteWhyBookWithYgtSolheimCup =
        customProp?.quoteWhyBookWithYgtSolheimCup?.content[0]?.content || [];

    const getMappedArrayByCondition = (condition: any) => {
        let selectedArray = [];

        switch (condition) {
            case 'The Open':
                selectedArray = quoteWhyBookWithYgtTheOpen;
                break;
            case 'Ryder Cup':
                selectedArray = quoteWhyBookWithYgtTheRyderCup;
                break;
            case 'Masters':
                selectedArray = quoteWhyBookWithYgtTheMasters;
                break;
            case 'Solheim Cup':
                selectedArray = quoteWhyBookWithYgtSolheimCup;
                break;
            default:
                return [];
        }
        const mappedArray = selectedArray.map((item: any, index: any) => {
            return {
                id: index + 1,
                content: item.content[0]?.content[0]?.value,
            };
        });

        return mappedArray;
    };
    const resultArray = getMappedArrayByCondition(firstTwoWords);

    return (
        <ItineraryInDetailStyle>
            {type === 'Standard' && (
                <div>
                    <IntineraryStandardComponent />
                </div>
            )}
            {type === 'Event' && (
                <div>
                    <ItineraryInDetailEventComponent />
                </div>
            )}
            {type === 'Event' && (
                <div>
                    {resultArray.length === 0 ? (
                        <div className="whyBookWithYGTComponent">
                            <Skeleton active={true} />
                        </div>
                    ) : (
                        <div className="whyBookWithYGTComponent">
                            <div className="pleaseRead">Why Book With YGT</div>
                            {resultArray?.map((item: any, index: any) => {
                                return (
                                    <div className="discriptionWBW" key={index}>
                                        <FontAwesomeIcon
                                            className="iconCheck"
                                            icon={faCircleCheck}
                                        />
                                        <div className="contentWBWYGT">
                                            {item?.content}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
            <div className="viewPleaseRead">
                <div className="pleaseRead">Please Read</div>
                <div className="contentPleaseRead">
                    Important Information About Your Proposed Trip
                </div>
                <div className="discriptionPleaseRead">
                    Please note that check-in will not be permitted without a
                    valid credit card or debit card. On arrival all guests will
                    be required to provide a valid credit card or debit card to
                    guarantee extras & incidentals. This card will be utili6ed
                    to administer a pre-authorisation charge of £50 per person.
                    The Belfry reserves the right, if required, to pair your
                    group with other players so that all tee times are complete
                    with 4 player All guests other than our spa packages will be
                    able to use the Leisure Facilities (excluding the Lire &
                    Ice) from 3pm on the day of arrival until 11am on the day
                    that they depart. Should they wish to use the facilities
                    outside of the times given, guests can purchase a pass for
                    earlier / later access. Dinner reservations must be
                    pre-booked, if customers require a dinner reservations
                    please advise your sales agent,who will add the request to
                    your booking.
                </div>
            </div>
        </ItineraryInDetailStyle>
    );
};

export default ItineraryInDetailComponent;
