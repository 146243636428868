import React from 'react';
import ImportantInformationStyle from './style';
import { useLocation } from 'react-router-dom';
import { Skeleton } from 'antd';

interface ImportantInforProps {
    dataImportanInfor?: any;
}
interface BaseNode {
    data: any;
    content?: Node[];
    nodeType: string;
    marks?: any[];
}

interface TextNode extends BaseNode {
    nodeType: 'text';
    value: string;
}

type Node = BaseNode | TextNode;
const ImportantInformationComponent: React.FC<ImportantInforProps> = ({
    dataImportanInfor,
}) => {
    const location: any = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');

    const extractValues = (node: Node): string[] => {
        let values: string[] = [];

        if (Array.isArray(node?.content)) {
            node?.content?.forEach((childNode: Node) => {
                if (childNode.nodeType === 'text') {
                    const textNode = childNode as TextNode;
                    values.push(textNode.value);
                } else {
                    values = values.concat(extractValues(childNode));
                }
            });
        }

        return values;
    };

    const valueImportantInfor = extractValues(
        type === 'Event'
            ? dataImportanInfor?.quoteImportantNotesTour
            : dataImportanInfor?.quoteImportantNotesStandard,
    );

    return (
        <ImportantInformationStyle>
            <div className="title">Important Notes</div>
            <div className="viewContent">
                {dataImportanInfor ? (
                    <ul>
                        {valueImportantInfor.filter(item => item !== '').map((item, index) => {
                            return <li key={index} className="content">{item}</li>;
                        })}
                    </ul>
                ) : (
                    <Skeleton active={true} />
                )}
            </div>
        </ImportantInformationStyle>
    );
};

export default ImportantInformationComponent;
