import styled from '@emotion/styled';

const IntineraryStandardStyle = styled('div')`
    .iconHotelorGolf {
        color: #12b897;
    }
    .iconDetail {
        color: #12b897;
    }
    .discriptRoundOfGofl {
        display: flex;
        margin-bottom: 10px;
        width: 100%;
        justify-content: space-around;
        align-items: center;
    }
    .iconDetailGolf{
        visibility: hidden;
    }
`;

export default IntineraryStandardStyle;
