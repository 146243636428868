const icons = {
    plusIcon: require('./plus-icon.svg').default,
    golfTravelExpert: require('./golf-travel-expert.svg').default,
    bestPriceGuaranteed: require('./best-price-guaranteed.svg').default,
    industryLeadingProtection: require('./industry-leading-protection.svg').default,
    unbeatableChoice: require('./unbeatable-choice.svg').default,
    leftArrow: require('./leftOutlined.svg').default,
    rightArrow: require('./rightOutlined.svg').default,
    qouteRight: require('./quote-right.svg').default,
    arrowLeft: require('./arrow-left.svg').default,
    hotelIcon: require('./hotel-icon.svg').default,
    iconFile: require('./icon-file.svg').default,
    arrowRight: require('./angle-right.svg').default,
    arrowDown: require('./arrow-down.svg').default,
    shareLink: require('./sharelink-icon.svg').default,
    download: require('./download-icon.svg').default,
    clockIcon: require('./clock-icon.svg').default,
    searchIcon: require('./search-icon.svg').default,
    stateLayer: require('./state-layer.svg').default,
    eyeIcon: require('./eye-icon.svg').default,
    step01: require('./step-01.svg').default,
    step02: require('./step-02.svg').default,
    step03: require('./step-03.svg').default,
    finalStep: require('./final-step.svg').default,
    shareLinkDisable: require('./shareLinkDisable.svg').default,
    minusIcon: require('./minus-icon.svg').default,
};
export default icons;
