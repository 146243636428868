import React from 'react';
import FlightCardStyle from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlane,
    faPlaneArrival,
    faPlaneDeparture,
} from '@fortawesome/free-solid-svg-icons';

const FlightCardComponent = () => {
    return (
        <FlightCardStyle>
            <div className="traditionView">
                <div className="viewDetailPlanFlight">
                    <div className="headerCard">
                        <div className="titleFightPlan">
                            Your Outbound Flights
                        </div>
                        <FontAwesomeIcon
                            className="iconTransportation"
                            icon={faPlane}
                        />
                    </div>
                    <div>
                        <div className="detailPlanflight">
                            <FontAwesomeIcon
                                className="iconTransportation"
                                icon={faPlaneDeparture}
                            />
                            <div>
                                <div className="locationName">
                                    London Heathrow (LHR)
                                </div>
                                <div className="textTimeFont">
                                    Departing 00:05
                                </div>
                            </div>
                        </div>
                        <div className="detailPlanflight">
                            <FontAwesomeIcon
                                className="iconTransportation"
                                icon={faPlaneArrival}
                            />
                            <div>
                                <div className="locationName">
                                    Charlotte Douglas International (CLT){' '}
                                </div>
                                <div className="textTimeFont">
                                    Arriving 11:50
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="viewFlightinformation">
                        <div className="textFont">Airline: AA725 Economy</div>
                        <div className="textFont">Passengers: 4</div>
                        <div className="textFont">
                            Extras: 1 piece of luggage x 4 Golf bags x 4
                        </div>
                    </div>
                </div>
            </div>
        </FlightCardStyle>
    );
};

export default FlightCardComponent;
