import styled from '@emotion/styled';

const ImportantInformationStyle = styled('div')`
    width: 100%;
    border-radius: 8px;
    background: #fff;
    /* shadow */
    box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
    padding: 30px 30px 60px 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
    .title {
        width: 95%;
        color: #000;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        margin-bottom: 24px;
        text-align: left;
    }
    .viewContent{
        width: 90%;
    }
    .content {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        list-style-type: disc;
    }
`;
export default ImportantInformationStyle;
