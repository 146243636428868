import React from 'react';
import CarHideCardStyle from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCar,
    faPlane,
    faPlaneArrival,
    faPlaneDeparture,
} from '@fortawesome/free-solid-svg-icons';

const CarHideCardComponent = () => {
    return (
        <CarHideCardStyle>
            <div className="traditionView">
                <div className="viewDetailPlanFlight">
                    <div className="headerCard">
                        <div className="titleFightPlan">
                            Pick Up Your Hire Car
                        </div>
                        <FontAwesomeIcon
                            className="iconTransportation"
                            icon={faCar}
                        />
                    </div>
                    <div>
                        <div className="detailPlanflight">
                            <FontAwesomeIcon
                                className="iconTransportation"
                                icon={faCar}
                            />
                            <div className="locationName">
                                Pick-up vehicle from Charlotte (Douglas
                                International) at 13:00
                            </div>
                        </div>
                    </div>
                    <div className="viewFlightinformation">
                        <div className="textFont">
                            Car type: Chevrolet Suburban
                        </div>
                        <div className="textFont">8 pax</div>
                    </div>
                </div>
            </div>
        </CarHideCardStyle>
    );
};

export default CarHideCardComponent;
