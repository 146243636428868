import React from 'react';
import IntineraryEventStyle from './styled';
import FlightCardComponent from 'src/components/flightCard';
import CarHideCardComponent from 'src/components/carHideCard';
import TransferCard from 'src/components/tranferCard';
import images from 'src/assets/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

const ItineraryInDetailEventComponent = () => {
    return (
        <IntineraryEventStyle>
            <div className="titleDay1">Day 1: Mon 5th August 2024</div>
            <FlightCardComponent />
            <CarHideCardComponent />
            <TransferCard />
            <div className="mainImage">
                <img alt="" className="imageEvent" src={images?.imageEvent} />
            </div>
            <div className="nameEventTicket">
                <div className='titleTicket'>Master Practice Round Ticket - Wednesday</div>
                <FontAwesomeIcon
                    className="iconCup"
                    icon={faTrophy}
                />
            </div>
        </IntineraryEventStyle>
    );
};

export default ItineraryInDetailEventComponent;
