import styled from '@emotion/styled';

const IntineraryEventStyle = styled('div')`
    margin-bottom: 16px;
    .titleFightPlan {
        color: #000;
        font-size: 20px;
        font-weight: 700;
    }
    .mainImage {
        border-radius: 8px;
        background: #fff;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        display: flex;
        justify-content: space-between;
    }
    .imageEvent {
        width: 100%;
        height: 223px;
        border-radius: 8px;
    }
    .nameEventTicket {
        background-color: #eef9ff;
        border-radius: 8px;
        height: 120px;
        margin-top: 20px;
        box-shadow: 4px 4px 20px 0px rgba(156, 171, 194, 0.4);
        padding: 24px;
        display: flex;
        justify-content: space-between;
    }
    .iconCup {
        color: #12b897;
    }
    .titleTicket {
        color: #000;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: 'Lato';
        font-size: 20px;
        font-weight: 700;
        line-height: 24.038px;
    }
`;

export default IntineraryEventStyle;
