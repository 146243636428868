import { faTaxi, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import TransferCardStyle from './style';

const TransferCard = () => {
    return (
        <TransferCardStyle>
            <div className="traditionView">
                <div className="viewDetailPlanFlight">
                    <div className="headerCard">
                        <div className="titleFightPlan">Shuttle Transfer</div>
                        <FontAwesomeIcon
                            className="iconTransportation"
                            icon={faTaxi}
                        />
                    </div>
                    <div>
                        <div className="detailPlanflight">
                            <FontAwesomeIcon
                                className="iconTransportation"
                                icon={faTaxi}
                            />
                            <div>
                                <div className="locationName">
                                    {`Holiday Express Inn Downtown Augusta To
                                    National Golf Course at 13:00`}
                                </div>
                                <div className="textTimeFont">
                                    2 Passengers{' '}
                                    <FontAwesomeIcon
                                        className="iconPassenger"
                                        icon={faUserGroup}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TransferCardStyle>
    );
};

export default TransferCard;
