import styled from '@emotion/styled';

const WhatOutGolferSayComponentStyle = styled('div')`
    width: 21%;
    .iconWhy {
        margin-bottom: 17px;
        width: 64.286px;
        height: 50.223px;
        object-fit: cover;
    }
    .titleItem {
        font-size: 16.071px;
        font-weight: 700;
        line-height: 22.098px;
        color: #4f4f4f;
        /* display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden; */
    }
    .discriptionItem {
        font-size: 16.071px;
        font-weight: 400;
        line-height: 22.098px;
        color: #4f4f4f;
        /* display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden; */
    }
    @media screen and (max-width: 430px) {
        display: flex;
        width: 100%;
        margin-bottom: 16px;
        .iconWhy {
            margin-bottom: 17px;
            width: 64.286px;
            height: 50.223px;
            object-fit: cover;
        }
        .groupDiscription {
            width: 75%;
        }
        .allItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
`;

export default WhatOutGolferSayComponentStyle;
