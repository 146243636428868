import styled from '@emotion/styled';

const IntroductionGoflTravelStyle = styled('div')`
    width: 100%;
    .viewMainImage {
        width: 100%;
        height: 413px;
        margin-bottom: 23px;
    }
    .imgGoflTravel {
        width: 100%;
        height: 100%;
        /* object-fit: cover; */
        border-radius: 6px;
    }
    .discriptionGoflTravel {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #dcdcdc;
        background: #fff;
        padding: 39px 35px 39px 35px;
        font-size: 16.071px;
        font-weight: 400;
        line-height: 20px;
        color: #4f4f4f;
        margin-bottom: 37px;
    }
    .content {
        width: 84%;
        word-wrap: break-word;
    }
    .signIn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: end;
    }
    .signatureMobile {
        font-family: 'The Nautigal', cursive;
        font-size: 44.196px;
        font-weight: 400;
        line-height: 44.196px;
        margin-top: 16px;
        display: none;
    }
    .inforSeller {
        text-align: right;
        font-size: 14.062px;
        font-style: normal;
        font-weight: 900;
        line-height: 20.391px;
    }
    .mailSeller {
        text-decoration-line: underline;
        color: #12b897;
    }
    .viewWhyContent {
        width: 100%;
    }
    .titleWhyContent {
        font-size: 23.758px;
        font-style: normal;
        font-weight: 700;
        line-height: 28.125px;
        margin-bottom: 23px;
    }
    .showItemWhy {
        display: flex;
        margin-top: 35px;
        justify-content: space-between;
        padding-bottom: 42px;
        border-bottom: 1px solid #dcdcdc;
    }
    .iconWhy {
        margin-bottom: 17px;
    }
    .titleItem {
        font-size: 16.071px;
        font-weight: 700;
        line-height: 22.098px;
        color: #4f4f4f;
    }
    .discriptionItem {
        font-size: 16.071px;
        font-weight: 400;
        line-height: 22.098px;
        color: #4f4f4f;
    }
    .slick-arrow .slick-next {
        width: 32.987px !important;
        height: 34.152px !important;
    }
    .carouselView {
        margin-top: 23px;
        border-bottom: 1px solid #dcdcdc;
        padding-bottom: 48px;
    }
    .showItemStep {
        display: flex;
        margin-top: 35px;
        justify-content: space-between;
        padding-bottom: 42px;
    }
    .endView {
        margin-top: 23px;
        padding-bottom: 48px;
    }
    .nameSeller {
        color: #12b897;
    }
    .viewItemCarousel {
        padding-left: 20%;
        padding-right: 20%;
    }
    .titleItemCarousel {
        display: flex;
        margin-bottom: 11px;
        align-items: center;
    }
    .pointCarousel {
        border-radius: 4px;
        background: #12b897;
        padding: 5px 10px 5px 10px;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 900;
        line-height: 22px;
        margin-right: 13px;
    }
    .commentCarousel {
        color: #000;
        font-size: 16px;
        font-weight: 900;
        line-height: 22px;
    }
    .discriptionCarousel {
        color: var(--Gray-2, #4f4f4f);
        font-size: 16.071px;
        font-weight: 400;
        line-height: 22.098px;
    }
    .SignCarousel {
        font-size: 16.071px;
        font-style: italic;
        font-weight: 700;
        line-height: 22.098px;
    }
    .avatarSeller {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .avatar {
        width: 87.065px;
        height: 89px;
        color: #12b897;
        object-fit: cover;
    }
    .avatarReals {
        width: 87.065px;
        height: 89px;
        color: #12b897;
        object-fit: cover;
        border-radius: 45px;
    }
    .backgroundAvatar {
        width: 93px;
        height: 95px;
        display: flex;
        background-color: #12b897;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
    }
    .avatarSignView {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .commentSeller {
        color: #4f4f4f;
        font-size: 16.071px;
        font-weight: 400;
        line-height: 22.098px;
        margin-right: 20px;
    }
    .signEnd {
        text-align: end;
        color: #454545;
        font-size: 14.062px;
        font-weight: 900;
        line-height: 145%;
    }
    .viewEnd {
        display: flex;
        justify-content: space-between;
    }
    .phone {
        color: #454545;
        font-size: 14.062px;
        font-weight: 900;
        line-height: 20.391px;
    }
    .email {
        color: #12b897;
        font-size: 14.062px;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        text-decoration-line: underline;
    }
    .signature {
        font-family: 'The Nautigal', cursive;
        font-size: 44.196px;
        font-weight: 400;
        line-height: 44.196px;
        margin-top: 16px;
    }
    .carouselMobile {
        display: none;
    }
    .btnSeeMore {
        color: #1a91ff;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0.16px;
        cursor: pointer;
    }
    .viewBtnSeeMore {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
    }
    .endIntroMobile {
        display: none;
    }
    .endIntroDesktop {
        display: block;
    }
    @media screen and (max-width: 768px) {
        .discriptionGoflTravel {
            padding: 35px 30px 35px 30px;
            font-size: 14px;
        }
        .titleWhyContent {
            font-size: 20px;
        }
        .viewItemCarousel {
            padding-left: 28%;
        }
        .commentSeller {
            width: 40%;
        }
        .signEnd {
            width: 100%;
            text-align: right;
            font-size: 13px;
        }
        .email {
            font-size: 13px;
        }
        .avatarSeller {
            width: 100%;
        }
        .avatarSignView {
            display: flex;
            flex-direction: column;
        }
        .avatar {
            width: 100%;
        }
        .titleItem {
            font-size: 14px;
        }
        .discriptionItem {
            font-size: 12px;
        }
    }
    @media screen and (max-width: 430px) {
        .showItemWhy {
            display: flex;
            flex-direction: column;
        }
        .content {
            width: 100%;
        }
        .groupWhyItem {
            width: 100%;
        }
        .carouselDesktop {
            display: none;
        }
        .carouselMobile {
            display: flex;
        }
        .viewMainImage {
            display: none;
        }
        .viewEnd {
            display: flex;
            flex-direction: column;
        }
        .commentSeller {
            width: 100%;
            color: #4f4f4f;
            font-size: 16.071px;
            font-weight: 400;
            line-height: 22.098px;
        }
        .signEnd {
            width: 100%;
            text-align: end;
            color: #454545;
            font-size: 14.062px;
            font-weight: 900;
            line-height: 145%;
        }
        .avatarSignView {
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-top: 16px;
            justify-content: space-between;
        }
        .avatarSeller {
            width: 100px;
        }
        .signEnd {
            width: 66%;
        }
        .signature {
            font-family: 'The Nautigal', cursive;
            font-size: 22.196px;
            font-weight: 400;
            line-height: 22.196px;
            display: none;
        }
        .signatureMobile {
            font-family: 'The Nautigal', cursive;
            font-size: 44.196px;
            font-weight: 400;
            line-height: 44.196px;
            margin-top: 16px;
            display: block;
        }
        .editViewInyto {
            width: 100%;
        }
        .endIntroMobile {
            display: block;
            margin-top: 16px;
        }
        .ql-container {
            height: 81%;
        }
        .endIntroDesktop {
            display: none;
        }
    }
`;

export default IntroductionGoflTravelStyle;
