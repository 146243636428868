import React from 'react';
import ViewYourMasterExperientComponentStyle from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBed,
    faBellConcierge,
    faGift,
    faMartiniGlassCitrus,
    faTaxi,
    faTicket,
    faTrophy,
} from '@fortawesome/free-solid-svg-icons';

const YourMasterExperientComponent = () => {
    return (
        <ViewYourMasterExperientComponentStyle>
            <div className="Header">
                Your Masters Experience 2025 At A Glance
            </div>
            <div className="Body">
                <div>
                    <div className="detailInfor">
                        <FontAwesomeIcon
                            className="iconDetail"
                            icon={faTrophy}
                        />
                        <div className="titleDetailInfor">
                            Masters entry ticket for Tournament Day Friday or
                            Saturday or Sunday (1 day)
                        </div>
                    </div>
                </div>
                <div>
                    <div className="detailInfor">
                        <FontAwesomeIcon className="iconDetail" icon={faTaxi} />
                        <div className="titleDetailInfor">
                            Shuttle from your hotel to AGNGC
                        </div>
                    </div>
                </div>
                <div>
                    <div className="detailInfor">
                        <FontAwesomeIcon
                            className="iconDetail"
                            icon={faMartiniGlassCitrus}
                        />
                        <div className="titleDetailInfor">
                            Hospitality on the day
                        </div>
                    </div>
                </div>
                <div>
                    <div className="detailInfor">
                        <FontAwesomeIcon className="iconDetail" icon={faGift} />
                        <div className="titleDetailInfor">Masters Gift</div>
                    </div>
                </div>
                <div>
                    <div className="detailInfor">
                        <FontAwesomeIcon
                            className="iconDetail"
                            icon={faTicket}
                        />
                        <div className="titleDetailInfor">
                            Invite to the YGT Private Party on Saturday
                        </div>
                    </div>
                </div>
                <div>
                    <div className="detailInfor">
                        <FontAwesomeIcon
                            className="iconDetail"
                            icon={faBed}
                        />
                        <div className="titleDetailInfor">
                            Evening if Masters on Saturday
                        </div>
                    </div>
                </div>
                <div>
                    <div className="detailInfor">
                        <FontAwesomeIcon className="iconDetail" icon={faBellConcierge} />
                        <div className="titleDetailInfor">
                            YGT Concierge Guest services in Augusta
                        </div>
                    </div>
                </div>
            </div>
        </ViewYourMasterExperientComponentStyle>
    );
};

export default YourMasterExperientComponent;
